import React, { useContext, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ErrorContext } from '../../../src/store/ErrorContext'
import PortalEntry from '../../pages/PortalEntry'
import {
  REMOVE_CARDBOARD_PAGE,
  SCALABLE_ERROR_PAGE
} from '../../config/constants'
import { WithSuspense } from '../../../src/utils/helpers'
import classes from './RemoveCardboard.modules.scss'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

const GenericErrorModal = lazy(() =>
  import(
    /* webpackChunkName: "modal-generic" */ '../../../src/components/LiveUi/GenericErrorModal'
  )
)
const CartridgeAlert = lazy(() =>
  import(
    /* webpackChunkName: "page-cartridge-alert" */ '../../pages/CartridgeAlert'
  )
)
const CalibrationAlert = lazy(() =>
  import(
    /* webpackChunkName: "page-calibration-alert" */ '../../pages/CalibrationAlert'
  )
)
const Completed = lazy(() =>
  import(/* webpackChunkName: "page-completed" */ '../../pages/Completed')
)

const RemoveCardboard = () => {
  const { error } = useContext(ErrorContext)
  const { uiBrand } = useMultiUiBrandContext()
  const isHPX = uiBrand === UiTheme.hpx

  return (
    <div
      data-testid={isHPX ? 'HPX' : 'HP-Smart'}
      className={isHPX ? classes['hpx-theme'] : ''}
    >
      {error && (
        <WithSuspense component={GenericErrorModal} props={{ error: error }} />
      )}
      <Routes>
        <Route
          path="completed"
          element={<WithSuspense component={Completed} />}
        />
        <Route
          path={`${REMOVE_CARDBOARD_PAGE}`}
          element={<WithSuspense component={CartridgeAlert} />}
        />
        <Route
          path={`${SCALABLE_ERROR_PAGE}`}
          element={<WithSuspense component={CalibrationAlert} />}
        />
        {/* Portal entry path */}
        <Route exact path="/" element={<PortalEntry />} />
      </Routes>
    </div>
  )
}
export default RemoveCardboard
