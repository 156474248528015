import DOMPurify from 'dompurify'
import {
  countryIsoDisplayName,
  DEFAULT_ERROR_CODE,
  DHP_ERROR_CODE,
  languageDisplayName,
  SERVICE_ID,
  countryDisplayName,
  DHP_NON_200_ERROR_CODE,
  _HIGH_PRIORITY_ORDERED_INK_ALERTS,
  SEVERITY_ERROR,
  CARTRIDGE_COLORS
} from '../config/constants'
import React, { Suspense } from 'react'
import { FullscreenProgress } from '../components/LiveUi/FullscreenProgress'
import Black from '../assets/images/Black.png'
import Cyan from '../assets/images/Cyan.png'
import Yellow from '../assets/images/Yellow.png'
import Magenta from '../assets/images/Magenta.png'
import CyanMagentaYellow from '../assets/images/CyanMagentaYellow.png'
import { languageFallback, localeFallback } from '../assets/locale'

const packageJson = require('../../package.json')

export const exportActionStateToList = (actionState) => {
  return Object.keys(actionState).map((key) => {
    return actionState[key].name
  })
}

export const logWithPrefix = (severity, log, prefix = 'LiveUI') => {
  console[severity](
    `${prefix} - ${packageJson.version} - `,
    log instanceof String ? log : JSON.stringify(log)
  )
}

export const Logger = {
  debug: (log) => logWithPrefix('debug', log),
  log: (log) => logWithPrefix('log', log),
  info: (log) => logWithPrefix('info', log),
  warn: (log) => logWithPrefix('warn', log),
  error: (log) => logWithPrefix('error', log)
}

export const sortOnKeys = (dict) => {
  const sorted = []
  for (const k in dict) {
    sorted[sorted.length] = k
  }
  sorted.sort()

  const tempDict = {}
  sorted.forEach((key) => {
    tempDict[key] = dict[key]
  })

  return tempDict
}

export const getJsonDataForModel = (json, modelName) => {
  for (const property in json) {
    if (json[property]['models'].includes(modelName))
      return json[property]['data']
  }
  return {}
}

export function parseJsonToArray(json) {
  return json ? Object.values(json) : []
}

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

export const generateError = (error, serviceId = SERVICE_ID) => {
  const errorCode =
    DHP_ERROR_CODE[error.errorType] ||
    (error.statusCode ? DHP_NON_200_ERROR_CODE : DEFAULT_ERROR_CODE)

  return {
    resourceUri: error.resourceUri,
    payload: error.payload,
    errorCode: `${serviceId}.${errorCode}`,
    errorDescription: `${error.errorType || error.statusCode} - ${
      error.reason ? error.reason : 'Error calling DHP plugin (no reason)'
    }`,
    errorCodeLabel: error.statusCode
      ? `${errorCode}_${error.statusCode}`
      : errorCode
  }
}

export const getCartridgeColor = (color) => {
  switch (color) {
    case CARTRIDGE_COLORS.LEDM.BLACK:
    case CARTRIDGE_COLORS.CDM.BLACK:
      return [Black]
    case CARTRIDGE_COLORS.LEDM.YELLOW:
    case CARTRIDGE_COLORS.CDM.YELLOW:
      return [Yellow]
    case CARTRIDGE_COLORS.LEDM.MAGENTA:
    case CARTRIDGE_COLORS.CDM.MAGENTA:
      return [Magenta]
    case CARTRIDGE_COLORS.LEDM.CYAN:
    case CARTRIDGE_COLORS.CDM.CYAN:
      return [Cyan]
    case CARTRIDGE_COLORS.LEDM.CYANMAGENTAYELLOW:
    case CARTRIDGE_COLORS.CDM.CYANMAGENTAYELLOW:
      return [CyanMagentaYellow]
    default:
      return []
  }
}

export const parseAlertByPriority = (
  alert,
  ignorable_alerts,
  is_ink_alert,
  highestPriorityAlert,
  known_alerts,
  cartridges,
  highestPriorityError
) => {
  const { StringId, Severity, Priority, Cartridge } = alert

  if (
    Severity === 'error' &&
    (!highestPriorityError.length ||
      Priority < highestPriorityError[0].Priority)
  ) {
    highestPriorityError[0] = alert
  }

  if (!StringId || !Severity || !Priority || ignorable_alerts[StringId]) {
    return
  }

  if (is_ink_alert && _HIGH_PRIORITY_ORDERED_INK_ALERTS[StringId]) {
    highestPriorityAlert.push(alert)
    return
  }

  if (known_alerts[StringId] && Severity !== 'error') {
    if (
      highestPriorityAlert.length === 0 ||
      Priority < highestPriorityAlert[0].Priority
    ) {
      highestPriorityAlert.unshift(alert)
      cartridges.length = 0
      cartridges.push(...getCartridgeColor(Cartridge))
    } else if (Priority === highestPriorityAlert[0].Priority) {
      cartridges.push(...getCartridgeColor(Cartridge))
    }
  }
}

export const returnAlertByPriority = (
  highestPriorityAlert,
  is_ink_alert,
  known_alerts,
  highestPriorityError,
  cartridges
) => {
  if (highestPriorityAlert.length > 0) {
    /* when is_insertink_alerts parameter is true
        highPriorityOrderedInkAlerts have the highest priority and should be handled in order. */
    if (
      is_ink_alert &&
      highestPriorityAlert.find(
        (alert) => _HIGH_PRIORITY_ORDERED_INK_ALERTS[alert.StringId]
      )
    ) {
      return {
        category:
          known_alerts[
            Object.keys(_HIGH_PRIORITY_ORDERED_INK_ALERTS).find((highest) =>
              highestPriorityAlert.find(
                (alert) => alert.StringId.toString() === highest
              )
            )
          ],
        cartridges
      }
    }
    return {
      category: known_alerts[highestPriorityAlert[0].StringId],
      cartridges
    }
  }

  return highestPriorityError.length
    ? { category: SEVERITY_ERROR, cartridges: [] }
    : { category: null, cartridges: [] }
}

export const isDHPError = (response) => {
  return !!(
    response?.errorType || range(200, 299).indexOf(response?.statusCode) === -1
  )
}

export const getLanguageCode = (language) => {
  return getKeyByValue(languageDisplayName, language)
}

export const getCountryIsoByDisplayName = (country) => {
  return getKeyByValue(countryIsoDisplayName, country)
}

export const getCountryByDisplayName = (country) => {
  return getKeyByValue(countryDisplayName, country)
}

export const getCountryByIsoCode = (countryIso) => {
  return getCountryByDisplayName(countryIsoDisplayName[countryIso])
}

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const isJsonType = (obj) => {
  return obj.constructor === {}.constructor
}

/**
 * Receive a JSON object and sanitize every string found in the object, including all children.
 * This only allows <b>target</b> properties to keep the behavior of anchor links.
 *
 * @param jsonData {{}} JSON that needs to be sanitized
 * @returns {{}} Sanitized JSON object
 */
export const sanitize = (jsonData) => {
  if (!jsonData) {
    return jsonData
  } else if (isJsonType(jsonData)) {
    Object.keys(jsonData).forEach((nd) => {
      jsonData[nd] = sanitize(jsonData[nd])
    })
    return jsonData
  } else if (Array.isArray(jsonData)) {
    jsonData.forEach((nd, index) => {
      jsonData[index] = sanitize(nd)
    })
    return jsonData
  } else {
    return DOMPurify.sanitize(jsonData, { ADD_ATTR: ['target'] })
  }
}

export const getDisplayName = (items, supportedItems) => {
  try {
    return items
      ?.filter((element) => supportedItems[element])
      .map((element) => ({ value: supportedItems[element] }))
  } catch (e) {
    Logger.warn(`Not able to get display name - ${e}`)
  }
  return []
}

export const WithSuspense = ({ props = null, component: Component }) => (
  <Suspense fallback={<FullscreenProgress />}>
    <Component {...props} />
  </Suspense>
)

export const findLocaleFallback = (language, country) => {
  const locale = `${language}_${country}`
  if (localeFallback[locale]) {
    return localeFallback[locale]
  }
  return languageFallback[language] ? languageFallback[language] : 'en_US'
}

export const parseCarouselCmsData = (response) => {
  const parsedCards = []
  if (response.cards) {
    for (let entry of response.cards) {
      parsedCards.push({
        animation: entry.card.animation.url,
        instruction: entry.card.instruction
      })
    }
  }
  return {
    header: response?.header,
    skip: response?.skip_button_text,
    continue: response?.continue_button_text,
    helpInfo: response?.help_info,
    cards: parsedCards
  }
}
